.window {
  background-color: #ffffff;
  width: 100%;
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 auto;
}

.before-frame {
  position: relative;
  height: auto;
}

.imagecover {
  position: relative;
  height: auto;
}

.mumservices-image {
  position: relative;
  display: flex;
  object-fit: cover;
  margin-top: 58px;
  width: 100%;
  height: auto;
}

.pagelanguage {
  position: relative;
  height: auto;
  width: 100%;
  padding: 10px 10px 0px 10px;
  display: flex;
  justify-content: flex-end;
}

.changelanguage {
  color: #515151;
  font-family: "Open Sans", Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  position: relative;
  display: flex;
}

.changelanguage select:focus {
  outline: none;
  border-color: #dadada;
  box-shadow: 0;
}

.button-container {
  position: relative;
  display: flex;
  background-color: #ffffff;
  width: 100%;
  height: 60px;
  padding: 10px;
  justify-content: flex-start;
}

.button-anmelden {
  background-color: #ff8a00;
  color: #515151;
  font-family: "Open Sans", Roboto, Arial, sans-serif;
  font-weight: 800;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 16px;
  width: 160px;
  position: absolute;
  right: 10px;
}

.icon-login {
  width: 21px;
  height: 21px;
  background-image: url("/src/img/login-icon.png");
  background-size: cover;
  margin-left: 5px;
}

.button-admin {
  background-color: #ff8a00;
  color: #515151;
  font-family: "Open Sans", Roboto, Arial, sans-serif;
  font-weight: 800;
  border: none;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 40px;
  font-size: 16px;
  width: 160px;
  right: 200px;
}

.button-admin:hover,
.button-anmelden:hover {
  background-color: #e57a00;
}
.welcome {
  color: #515151;
  font-family: "Open Sans", Roboto, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: auto;
  position: relative;
  text-align: right;
  white-space: nowrap;
  letter-spacing: 0.0178571429em;
  overflow-wrap: inherit;
  word-break: break-all;
  text-overflow: ellipsis;
  right: 10px;
}

.button-back {
  border: none;
  background: none;
  width: auto;
  height: 40px;
  cursor: pointer;
  outline: none;
  opacity: 0.4;
  display: flex;
  align-items: center;
}
.button-back img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.frame {
  background-color: #ffffff;
  width: 100%;
  max-width: 1560px;
  padding: 45px 16px 100px 16px;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 0px;
}

.frame .tile {
  background-color: #ffffff;
  box-shadow: 0px 2px 4px #00000040;
  height: 418px;
  border-radius: 4px;
  overflow: hidden;
  display: grid;
  grid-template-rows: 20% 54% 7% 12% 7%;
}

.logofield {
  width: 100%;
  height: 80px;
}

.frame
  .logo-customx
  .logo-exs
  .logo-mumbimbooster
  .logo-mumsteelworkforinventor
  .logo-mapedit
  .logo-ginfo
  .logo-mumpdmbooster
  .logo-mumqtobooster
  .logo-mummultitoolforinventor
  .logo-mumpraxispaketstahlbau
  .logo-mumautocadtoolbox
  .logo-mummaterialbrowserforinventor {
  object-fit: cover;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.logo-mumpraxispaketstahlbau {
  padding: 0 5px 0 5px;
}

.card-body {
  display: grid;
  grid-template-rows: 23% 78%;
}

.card-info {
  display: grid;
  grid-template-columns: 30% 30%;
  padding: 0px 15px;
}

.card-actions {
  display: grid;
  grid-template-columns: 1fr 31px;
  gap: 10px;
  padding: 0px 15px;
  align-items: center;
}

.card-learnmore {
  display: grid;
  grid-template-columns: 0.28fr;
  justify-content: end;
  padding: 0px 15px;
}

.frame .title {
  color: #515151;
  font-family: "Open Sans", Roboto, Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  padding: 0px 20px;
  letter-spacing: 0.0178571429em;
  overflow: auto;
  word-break: keep-all;
  text-overflow: inherit;
  margin-bottom: 0;
}

.frame .description {
  color: #515151;
  font-family: "Open Sans", Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  padding: 5px 20px 5px 20px;
  letter-spacing: 0.0178571429em;
  overflow: auto;
  word-break: keep-all;
  text-overflow: inherit;
}
.language {
  margin-left: 10px;
}
.year {
  width: 60%;
}
.frame .year,
.frame .language {
  color: #515151;
  font-family: "Open Sans", Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  background: transparent;
  border: none;
  letter-spacing: 0.0178571429em;
}

.frame .button-download,
.frame .button-others,
.frame .button-learnmore {
  background-color: #ff8a00;
  color: white;
  font-family: "Open Sans", Roboto, Arial, sans-serif;
  font-weight: 400;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 31px;
  letter-spacing: 0.0178571429em;
}

.frame .button-download {
  font-size: 16px;
  font-weight: 600;
}

.frame .button-learnmore {
  font-size: 10px;
  height: 20px;
  border-radius: 8px;
  white-space: nowrap;
}

.others-container {
  background-color: #ffffff;
  width: calc(100% - 100px);
  height: auto;
  margin-top: 60px;
  position: relative;
  white-space: nowrap;
  padding-bottom: 100px;
}

.others-year-logo-container {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  height: 90px;
  position: relative;
}

.others-year-container {
  background-color: #ffffff;
  /*width: 100%;*/
  height: auto;
  position: relative;
  margin-top: 50px;
  vertical-align: middle;
}
.seperator {
  border-bottom: 2px solid #e3e3e4;
  width: 100%;
  margin-top: 17px;
}
.others-year-button {
  position: relative;
  border: none;
  background: none;
  font-family: "Open Sans", Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  top: 3px;
  cursor: pointer;
  color: #515151;
  margin: 0px 100px 0 0;
  transition: color 0.3s ease;
}

.others-year-button::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 25px;
  height: 2px;
  background-color: #515151;
  transition: background-color 0.3s ease;
}

.others-year-button:hover,
.others-year-button.selected {
  color: #ff8a00;
}

.others-year-button:hover::after,
.others-year-button.selected::after {
  background-color: #ff8a00;
}

.others-category-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.category-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.others-year-category-button {
  display: inline-block;
  position: relative;
  border: none;
  background: none;
  font-family: "Open Sans", Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  top: 55px;
  left: 0px;
  cursor: pointer;
  color: #515151;
  margin: 0px 50px 0px 0px;
  transition: color 0.3s ease;
}

.others-year-category-button::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 25px;
  height: 2px;
  background-color: #515151;
  transition: background-color 0.3s ease;
}

.others-year-category-button:hover,
.others-year-category-button.selected {
  color: #ff8a00;
}

.others-year-category-button:hover::after,
.others-year-category-button.selected::after {
  background-color: #ff8a00;
}
.category-description-container {
  margin-top: 80px;
  width: 80%;
}

.category-description-textarea .ql-editor,
.category-description-text {
  width: 100%;
  min-height: 100px;
  padding: 5px;
  font-size: 14px;
  border-radius: 5px;
  border: none;
  overflow: hidden;
  resize: none;
  background-color: #ffffff;
  color: #515151;
}

.category-description-text {
  word-wrap: break-word;
  white-space: pre-wrap;
  background-color: #ffffff;
  color: #515151;
}
.save-description-button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #ff8a00;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.category-table {
  display: inline-table;
  position: relative;
  border: none;
  background: none;
  font-family: "Open Sans", Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  border-collapse: collapse;
  width: 100%;
  margin: 20px 0px 0px 0px;
  text-align: left;
}

.category-table th {
  border-bottom: 2px solid #515151;
  padding: 0px 10px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
}

.category-table td {
  border-bottom: 1px solid #515151;
  padding: 8px 12px;
  text-align: left;
  font-family: "Open Sans", Roboto, Arial, sans-serif;
  font-size: 14px;
}

.others-table-download,
.others-table-releasenotes {
  display: inline-block;
  background-color: #ff8a00;
  color: white;
  font-family: "Open Sans", Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 0 20px;
  letter-spacing: 0.0178571429em;
}
.others-table-download:hover,
.others-table-releasenotes:hover {
  background-color: #e57a00;
}
.release-notes-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: "Open Sans", Roboto, Arial, sans-serif;
  color: #515151;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 50%;
  max-height: 70%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  overflow-y: auto;
  word-wrap: break-word;
  padding: 20px;
}
.button-container-releasenotes {
  display: grid;
  gap: 15px;
  background-color: #ffffff;
  width: 100%;
  height: auto;
  padding: 10px;
  justify-content: center;
}

.button-container-releasenotes-center {
  grid-template-columns: 0.1fr;
  justify-content: center;
}

.button-container-releasenotes {
  grid-template-columns: 0.1fr 0.1fr;
}

.button-container-releasenotes {
  grid-template-columns: 0.1fr 0.1fr 0.1fr;
}
.releasenotes-button {
  margin-top: 20px;
  background: #ff8a00;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-family: "Open Sans", Roboto, Arial, sans-serif;
  color: #515151;
  font-weight: 600;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.0178571429em;
}

.releasenotes-button:hover {
  background: #e57a00;
}

.sort-arrows {
  font-size: 12px;
  color: #515151;
  cursor: pointer;
}
.sortable-column .grid-container {
  display: grid;
  grid-template-columns: auto 12px;
  align-items: center;
  gap: 3px;
}

.align-left .grid-container {
  justify-content: start;
  text-align: left;
}

.centered .grid-container {
  justify-content: center;
  text-align: center;
}

.grid-container span {
  margin: 0;
  padding: 0;
}

.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 5px 0;
  bottom: 33px;
  background-color: transparent;
  text-align: center;
}

.footer-content {
  text-align: center;
  font-size: 13px;
  color: #515151;
  font-family: "Open Sans", Roboto, Arial, sans-serif;
}

.footer-link {
  color: #515151;
  text-decoration: underline;
  font-weight: normal;
}

.footer-link:hover {
  color: #ff8a00;
  text-decoration: underline;
}